/**
 * Util functions for dealing with object literals
 */

/**
 * Does a deep clone of an object literal
 * @param objectToClone
 */
export function deepClone(objectToClone: any): any {
  return JSON.parse(JSON.stringify(objectToClone));
}

export function objectsEqual(object1: any, object2: any): boolean {
  // IMPROVEMENT: This will return false if object1 has its properties populated in a different order than object2
  return JSON.stringify(object1) === JSON.stringify(object2);
}

export function emptyObject(object: any): boolean {
  for (var key in object) {
    if (object.hasOwnProperty(key))
      return false;
  }
  return true;
}

export function hasKeyIgnoreCase(object: any, keyToFind: string): boolean {
  keyToFind = keyToFind.toLowerCase();
  return !!Object.keys(object).find(key => key.toLowerCase() === keyToFind);
}