import {getAuthTokenAuthorizationHeader} from './AuthUtil';
import {hasKeyIgnoreCase} from "utils/ObjectUtil";

export type Json = { [key: string]: any };

export default class FetchUtil {
  static async fetchJson(url: string, options?: Json): Promise<{ [key: string]: any }> {
    options = prepOptions(options);
    options = setJsonContentTypeIfEmpty(options);
    return await fetch(url, options)
      .then(handleErrorResponse)
      .then(handleJsonResponse);
  }

  static async send(url: string, options?: Json): Promise<undefined> {
    return await fetch(url, prepOptions(options))
      .then(handleErrorResponse);
  }

  static async fetchText(url: string, options?: Json): Promise<string> {
    return await fetch(url, prepOptions(options))
      .then(handleErrorResponse)
      .then(handleTextResponse);
  }

  static async fetchFile(url: string, options?: Json): Promise<File> {
    const response = await fetch(url, prepOptions(options));
    
    if (!response.ok)
      return Promise.reject(response);
    
    const fileName = getFileNameFromResponse(response);
    const fileType = getFileTypeFromResponse(response);
    const blob = await response.blob();
    return new File([blob], fileName, {type: fileType});
  }
}

async function handleErrorResponse(response) {
  if (response.ok) {
    return response;
  }

  if (isUnauthorized(response.status))
    return redirectToLogin()

  const exceptionToThrow = new Error(response.statusText || response.status);

  try {
    exceptionToThrow['response'] = response;
    const jsonError = await response.json();

    if (jsonError["correct_account_id"] && window.location.href.indexOf('/accounts/') === -1)
      handleInvalidAccountError(jsonError, exceptionToThrow);
    else if (jsonError["error_messages"])
      exceptionToThrow['errors'] = jsonError["error_messages"];
  } catch (e) {
    throwSystemError(response, e);
  }

  throw exceptionToThrow;
}

function isUnauthorized(status) {
  return status === 401
}

function redirectToLogin() {
  window.location.href = "/";
}

function handleInvalidAccountError(invalidAccountError, exceptionToThrow) {
  window.location.href = window.location.origin + '/accounts/' + invalidAccountError["correct_account_id"] + window.location.pathname;
  exceptionToThrow['errors'] = false; // This will prevent the error from appearing on the screen while it is reloading
}

async function handleJsonResponse(response) {
  try {
    return await response.json();
  } catch (e) {
    // Catch any issues when parsing the response body as JSON.
    throwSystemError(response, e);
  }
}

async function handleTextResponse(response) {
  try {
    return await response.text();
  } catch (e) {
    // Catch any issues when parsing the response body as text.
    throwSystemError(response, e);
  }
}

function prepOptions(options?: Json): Json {
  if (!options)
    options = {};

  if (!options.credentials)
    options.credentials = 'include';

  if (options.includeAuthToken !== false) {
    if (!options.headers)
      options.headers = {};
    options.headers = Object.assign(options.headers, getAuthTokenAuthorizationHeader());
  } else
    options.includeAuthToken = null; // Prevent this option from being included in the request

  return options;
}

function getFileNameFromResponse(response) {
  const contentDispositionHeader = response.headers.get('Content-Disposition');
  const filenameRegex = /filename="(.*?)"/;
  const filenameMatch = filenameRegex.exec(contentDispositionHeader);

  return filenameMatch ? filenameMatch[1] || '' : '';
}

function throwSystemError(response, e?): void {
  const error = new Error(response.statusText || response.status);
  error['response'] = response;
  error['errors'] = ["A system error occurred. We have been notified of the problem."];

  if (e)
    error['errors'].push(e);

  throw error;
}

function getFileTypeFromResponse(response) {
  return response.headers.get('Content-Type') || 'text/plain';
}

function setJsonContentTypeIfEmpty(options: Json): Json {
  if (!options.headers)
    options.headers = {};

  if (!hasKeyIgnoreCase(options.headers, 'content-type'))
    options.headers['Content-Type'] = 'application/json';

  return options;
}