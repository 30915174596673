import FetchUtil from './FetchUtil';
import queryString from 'query-string';
import LocalStorageUtil, {StorageKey} from "./LocalStorageUtil";

const USER_TOKEN_CREATION_ENDPOINT = '/api/user_token';
const USER_TOKEN_VERIFICATION_ENDPOINT = '/api/verify_token';

type TokenReadyCallback = (authToken: string | null) => any;

let authToken: string | null = null;
let isAuthTokenReady: boolean = false;
const waitingCallbacks: TokenReadyCallback[] = [];

// If localStorage is available and accessing it doesn't throw any error, authToken can be retrieved at any time
authToken = LocalStorageUtil.get(StorageKey.jwt);
if (authToken)
  isAuthTokenReady = true;

if (!isAuthTokenReady && window.parent) {
  window.addEventListener('message', event => {
    if (event.data && event.data.action === 'authTokenLoaded')
      updateLocalAuthToken(event.data.authToken);
  });

  window.parent.postMessage({
    action: 'loadCliveAuthToken'
  }, '*');
}

function updateLocalAuthToken(token?: string | null) {
  isAuthTokenReady = true;
  authToken = token || null;
  waitingCallbacks.forEach((waitingCallback: TokenReadyCallback) => waitingCallback(authToken));
  waitingCallbacks.length = 0;
}

function handleAuthStorage(token: string) {
  updateLocalAuthToken(token);

  if (LocalStorageUtil.store(StorageKey.jwt, token)) {
    // After logging in reset current site and account, which could be old settings used by user who was previously using this browser
    LocalStorageUtil.delete(StorageKey.currentSiteId);
    LocalStorageUtil.delete(StorageKey.currentAccountId);

    return;
  }

  // Fallback when accessing localStorage throws an error or is null/undefined, which can happen in a Cascade iframe when 3rd party cookies are disabled
  if (window.parent)
    window.parent.postMessage({action: 'saveCliveAuthToken', authToken}, '*');
}

export function onAuthTokenReady(callback: TokenReadyCallback) {
  if (isAuthTokenReady)
    callback(authToken);

  waitingCallbacks.push(callback);
}

export function storedAuthTokenExists(): boolean {
  return authToken !== null;
}

export function deleteStoredAuthToken(): void {
  updateLocalAuthToken(null);

  if (LocalStorageUtil.delete(StorageKey.jwt))
    return;

  // Fallback when accessing localStorage throws an error or is null/undefined, which can happen in a Cascade iframe when 3rd party cookies are disabled
  if (window.parent)
    window.parent.postMessage({action: 'deleteCliveAuthToken'}, '*');
}

export function getAuthTokenAuthorizationHeader(): { Authorization: string } {
  return {'Authorization': "Bearer " + authToken};
}

/**
 * Attempts to retrieve a new authentication token for the user using his/her username + password and
 * returns a Promise for hooking into a successful login or catching an error.
 * @param username
 * @param password
 * @return Promise
 */
export async function getNewAuthToken(username: string, password: string) {
  const request = {"auth": {"email": username, "password": password}};

  return FetchUtil.fetchJson(USER_TOKEN_CREATION_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify(request),
    includeAuthToken: false
  })
    .then(json => handleAuthStorage(json.jwt));
}

/**
 * Determines if the currently stored JWT (if there is one) is still valid.
 * @returns {boolean} returns false if there is no stored JWT or if it is no longer valid and true otherwise.
 */
export function verifyAuthTokenIsValid(callbacks) {
  callbacks = callbacks || {};
  if (!storedAuthTokenExists())
    if (callbacks.failure)
      return callbacks.failure();

  FetchUtil.fetchJson(USER_TOKEN_VERIFICATION_ENDPOINT, {
    method: 'POST',
  })
    .then(json => {
      if (callbacks.success)
        callbacks.success(json);
    })
    .catch(error => {
      if (callbacks.failure)
        callbacks.failure(error);
    });
}

export function checkGoogleLogin(locationSearch, callbacks) {
  const query = queryString.parse(locationSearch);

  if (query.error) {
    if (callbacks.failure)
      callbacks.failure();
  }

  if (query.jwt) {
    handleAuthStorage(query.jwt);

    if (callbacks.success)
      callbacks.success();
  }
}