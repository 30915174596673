import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

import Account from "types/Account";
import User from "types/User";
import Environment from "components/Environment";
import ApiAssetUtil from "utils/ApiAssetUtil";
import {History} from 'history';

/**
 * Sentry related utility functions
 */
export default class SentryUtil {
  private static sentryInitialized: boolean = false;

  static init(history: History) {
    if (Environment.isProduction()) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
          new Integrations.BrowserTracing({
            // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
          }),
        ],
        environment: Environment.toString(),
        release: process.env.REACT_APP_HEROKU_SLUG_COMMIT,
        tracesSampleRate: 0,
      });

      Sentry.configureScope((scope) => {
        scope.setTag('clive_source', 'client');
      });

      this.sentryInitialized = true;
    }
  }

  /**
   * Adds context about the user to Sentry when reporting events.
   *
   * @param {User} user - The user associated with the Sentry event
   * @param {Account} account - The account associated with the Sentry event
   */
  static setUserContext(user: User, account: Account) {
    if (!this.sentryInitialized) {
      return;
    }

    if (ApiAssetUtil.isNotEmptyAsset(user)) {
      Sentry.setUser({
        id: user.id + "",
        email: user.email,
        account_id: ApiAssetUtil.isNotEmptyAsset(account) ? account.id : ""
      });
    }
  }
}
