import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';
import {createBrowserHistory, History} from 'history';
import {chunk} from "utils/LazyUtil";
import SentryUtil from "utils/SentryUtil";

const EmbeddedApp = chunk(() => import(/* webpackChunkName: "app-embed" */ "./EmbeddedApp"));
const App = chunk(() => import(/* webpackChunkName: "app" */"./App"));

// import registerServiceWorker from './registerServiceWorker';
const history: History = createBrowserHistory();

SentryUtil.init(history);

if (window.location.href.indexOf('http://') === 0)
  window.location.href = window.location.href.replace('http://', 'https://');
else if (window.location.pathname.indexOf('/embed') === 0)
  ReactDOM.render((<Router history={history}><Suspense fallback={<React.Fragment/>}><EmbeddedApp/></Suspense></Router>), document.getElementById('root'));
else
  ReactDOM.render((<Router history={history}><Suspense fallback={<React.Fragment/>}><App/></Suspense></Router>), document.getElementById('root'));
// registerServiceWorker();
