import ApiAsset from "types/ApiAsset";

export default class ApiAssetUtil {
  static TYPE_TO_LABEL: { [key: string]: string } = {
    account: "Account",
    custom_field: "Custom Field",
    email: "Email",
    form: "Form",
    form_submission: "Form Submission",
    segment: "Segment",
    smart_content: "Smart Content",
    site: "Site",
    user: "User",
    visit: "Visit",
    visitor: "Visitor",
    visitor_attribute: "Visitor Attribute",
    webhook: "Webhook"
  };

  static TYPE_TO_CLIENT_ENDPOINT: { [key: string]: string } = {
    account: "accounts",
    custom_field: "customfields",
    email: "emails",
    form: "forms",
    form_submission: "submissions",
    segment: "segments",
    smart_content: "smartcontents",
    site: "sites",
    user: "users",
    visit: "visits",
    visitor: "visitors",
    visitor_attribute: "visitorattributes"
  };

  static CLIENT_ENDPOINT_TO_TYPE: {[key: string]: string} = {
    accounts: "account",
    custom_fields: "custom_field",
    emails: "email",
    forms: "form",
    submissions: "submission",
    segments: "segment",
    smart_contents: "smart_content",
    sites: "site",
    users: "user",
    visits: "visit",
    visitors: "visitor",
    visitor_attributes: "visitor_attribute"
  };

  static TYPE_TO_API_ENDPOINT: { [key: string]: string } = {
    account: ApiAssetUtil.TYPE_TO_CLIENT_ENDPOINT["account"],
    custom_field: "custom_fields",
    email: ApiAssetUtil.TYPE_TO_CLIENT_ENDPOINT["email"],
    form: ApiAssetUtil.TYPE_TO_CLIENT_ENDPOINT["form"],
    form_submission: "form_submissions",
    segment: "segments",
    smart_content: "smart_contents",
    site: ApiAssetUtil.TYPE_TO_CLIENT_ENDPOINT["site"],
    user: ApiAssetUtil.TYPE_TO_CLIENT_ENDPOINT["user"],
    visit: "visits",
    visitor: "visitors",
    visitor_attribute: "visitor_attributes",
    webhook: "form_webhooks"
  };

  static getLabelFromType(assetType: string): string {
    return this.TYPE_TO_LABEL[assetType];
  };

  static getApiEndpointFromType(assetType: string): string {
    return this.TYPE_TO_API_ENDPOINT[assetType];
  };

  static getClientEndpointFromType(assetType: string): string {
    return this.TYPE_TO_CLIENT_ENDPOINT[assetType];
  };

  static getTypeFromClientEndpoint(assetType: string): string {
    return this.CLIENT_ENDPOINT_TO_TYPE[assetType];
  };

  static isNotEmptyAsset(asset: ApiAsset): boolean {
    return asset && !!asset.id;
  };
}